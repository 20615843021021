<template>
  <div class="not_suport_box">
    <div class="icon">
      <i class="el-icon-info"></i>
    </div>
    <div>
      <span>您最多只能上传{{ uploadLimit }}张图片。</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadLimit: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
 // 不支持上传
 .not_suport_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -35px;
            user-select: none;
            color: #333333;

            .icon {
                width: 40px;
                height: 40px;
                margin-bottom: 17px;

                >.el-icon-info {
                    font-size: 41px;
                    color: rgb(254, 138, 20);
                }
            }
        }
</style>